
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { Lead } from "@/models/leads"
  import { Leadsource } from "@/models/leadsources"
  import { CollectionResourceDoc, Included, ResourceIdentifierObject } from '@/models/jsonapi'
  import { ResourceObject } from "@/models/jsonapi";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher } from "@/utils/apiDataHelper"
  import { sortElements, sortData } from "@/utils/tableSort"
  import { internalLeadIndexColumns } from "@/view_scripts/table_columns/lead_columns"
  import { newLeadSchema, newLeadUISchema } from "@/view_scripts/form_schemas/lead"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  interface Form {}

  export default defineComponent({
    components: {
      SchemaForm
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.leads.pagination.totalItems)
      const leadEditModal = ref()
      const leadsources = computed<CollectionResourceDoc<string, Leadsource>>(() => root.$store.state.proto_leadsources.data)
      const columns = ref(internalLeadIndexColumns)
      const lastLeadsourceId = ref<string>()
      const newLeadForm = ref<ResourceObject<string, Lead>>({
        type: 'leads',
        attributes: { cost: 0, price: 30, assignPooled: true }
      })
      const uiSchema = computed(() => {
        if (leadsources.value) return newLeadUISchema(leadsources.value)
      })

      const getItems = async(ctx: BvTableCtxObject) => {
        const { sortKey, sortColumn, sortFunction } = sortElements(ctx, columns.value)
        const queryParams = {
          ...(ctx.filter as any),
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': sortFunction ? undefined : `${ctx.sortDesc ? '-' : ''}${ctx.sortBy?.replace('attributes.', '') ?? ''}`,
          'include':'address,proto_leadsource,seller,households',
        }
        await root.$store.dispatch('leads/load', queryParams)
        if (sortFunction) { sortData(ctx, root.$store.state.leads.data, sortFunction) }
        return root.$store.state.leads.data
      }

      const filterRules = [
        {
          id: "address.first_name",
          label: "Vorname",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "address.last_name",
          label: "Nachname",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "createdAt",
          label: "Erstellt am",
          type: "date",
          operators: ['is', 'is not']
        },
        {
          id: "proto_leadsource.name",
          label: "Leadquelle",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "seller.first_name",
          label: "KD",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "archived",
          label: "Archived",
          type: "boolean"
        },
        {
          id: "reclaimed",
          label: "Reclaimed",
          type: "boolean"
        }
      ]

      const titleActions = ref([
        {
          title: 'New Lead',
          icon: 'plus',
          tooltip: 'Neuer Lead',
          onClick: () => window.location.href = '/v2/leads'
        }
      ])

      const getLeadsources = () => {
        const leadSourceQueryParams = { 'filter[_type]': 'leadsource' }
        root.$store.dispatch('proto_leadsources/load', leadSourceQueryParams)
      }
      getLeadsources()

      const updatedForm = (formValue) => {
        if (formValue.attributes.protoLeadsourceId && lastLeadsourceId.value !== formValue.attributes.protoLeadsourceId) {
          const lastLeadSource = (leadsources.value as any).find((l) => l.id === formValue.attributes.protoLeadsourceId)
          lastLeadsourceId.value = lastLeadSource.id
          formValue.attributes.cost = lastLeadSource.attributes.cost ?? 0
          formValue.attributes.price = lastLeadSource.attributes.price ?? 30
        }
        newLeadForm.value = formValue
      }

      const saveLead = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        await root.$store.dispatch('leads/create', newLeadForm.value)
        root.$nextTick(() => leadEditModal.value.hide())
      }

      return {
        getItems,
        totalItems,
        columns,
        filterRules,
        leadEditModal,
        titleActions,
        newLeadForm,
        newLeadSchema,
        uiSchema,
        updatedForm,
        lastLeadsourceId,
        saveLead
      }
    }
  })
